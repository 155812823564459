html {
    background-color: #ffffff;
    box-sizing: border-box;
}

body {
    font-family: 'Raleway', sans-serif;
}

.body-wrapper {
    overflow-x: hidden;
}
*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
}

.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}
@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}
@media (min-width: 1400px) {
    .container {
        max-width: 1200px;
    }
}


/* Banner */
.banner{
    background: #FFFFFF;
    box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px 10px 10px;
    display: grid;
    gap: 30px;
    align-items: center; 
    grid-template-columns: 0.8fr 2fr 0.7fr;
}
.banner .logo-box{
    background: #EAF2F8;
    border-radius: 10px;
}
.banner .logo-box a{
    background: url(../image/domainer_logo.svg);
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    display: block;
    font-size: 0;
    padding: 20px 30px;
    height: 138px;
}
.banner .title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 60px;
    color: #090909;
}
.banner .info{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7D7F82;
    position: relative;
    margin-left: 15px;
}
.banner .info:before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    margin-left: -15px;
    border-radius: 10px;
    background: #F27558;
}
.banner .domaner-btn{
    background: #F27558;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    padding: 23px 5px;
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #000000;
}
.banner .domaner-btn:hover {
    background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
    .banner .title{
        font-size: 22px;
        line-height: 50px;
        margin-bottom: 10px;
    }
    .banner .info{
        font-size: 18px;
    }
    .banner .domaner-btn{
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
        line-height: 22px;
        display: table;
        margin: 0 auto;
        padding: 23px 30px;
    }
}
  @media only screen and (max-width: 940px) {
    .banner{
        grid-template-columns: 1fr;
        padding: 10px;
    }
    .banner .logo-box a{
        background: url(../image/domainer_logo_mob.svg);
        background-repeat: no-repeat;
        background-position: center center;
        height: 66px;
    }
    .banner .title{
        line-height: 24px;
        text-align: center;
    }
    .banner .info{
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }
    .banner .info:before{
        content: none;
    }
  }
  @media only screen and (max-width: 440px) {
    .banner .domaner-btn {
        display: block;
        padding: 23px 0px;
    }
  }

  .container .domainer::before{
    content: none !important;
  }


h1 {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 59px;
    color: #262A49;
    margin-left: -50px;
    padding-bottom: 30px;
}


h1 span {
    display: inline-block;
    position: relative;
}

h1 span::before {
    position: absolute;
    content: "";
    width: 90%;
    height: 75%;
    background-color: #fbc343;
    z-index: -1;
    bottom: 0;
    left: 5%;
}

h2 {

font-family: 'Lato';
font-style: normal;
font-weight: 900;
font-size: 40px;
line-height: 60px;
color: #262A49;
padding-bottom: 25px;
}

h3 {
    font-family: 'Lato';
font-style: normal;
font-weight: 800;
font-size: 32px;
line-height: 60px;
color: #262A49;
}

p {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #262A49;
}

p:not(:last-of-type) {
    padding-bottom: 25px;
}

.header-description {
    font-family: 'Raleway';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 30px;
color: #34374F;
}

.site-logo {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
    color: #262A49;
    position: relative;
    padding-left: 36px;
}

.site-logo::before {
    position: absolute;
    content: "";
    background-image: url(../image/logo-nftkyc.svg);
    width: 30px;
    height: 30px;
    background-size: contain;
    background-repeat: no-repeat;
    left: 0;
    top: -3px;

}

.logo-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 40px 0;
}

.logo-wrapper ul {
    display: flex;
    gap: 85px;
}

.logo-wrapper ul li a { 
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    color: #262A49;
    transition: all 0.2s ease-in-out;
}

.logo-wrapper ul li a:hover { 
    color: #3A6BEA;
}

.site-header-inner {
    display: grid;
    grid-template-columns: 53% 1fr;
    padding-top: 60px;
}

.site-header-img {
    position: relative;
    z-index: 2;
}

.site-header-img::before {
    position: absolute;
    content: "";
    background-image: url(../image/kyc-hero.svg);
    width: 110%;
    height: 350px;
    left: -58px;
    top: 15px;
    background-size: contain;
    background-repeat: no-repeat;
}

.site-header .container {
    position: relative;
}

.site-header .container::before {
    position: absolute;
    content: "";
    top: 0;
    left: 18%;
    width: 200%;
    height: 860px;
    background-color: #e5ecff;
    z-index: -1;
}

.what-are {
    padding: 80px 0;
}

.what-are h2 {
    text-align: center;
}

.what-are-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
    margin-top: 35px;
}

.what-are-wrapper h3 {
    position: relative;
    padding-left: 67px;
    padding-bottom: 10px;
}

.nfts h3::before {
    position: absolute;
    content: "";
    background-image: url(../image/nft.svg);
    background-repeat: no-repeat;
    background-size: contain;
    width: 58px;
    height: 58px;
    left: 0;
}

.kycs h3::before {
    position: absolute;
    content: "";
    background-image: url(../image/magnifier.svg);
    background-repeat: no-repeat;
    background-size: contain;
    width: 54px;
    height: 54px;
    left: 0;
    top: 2px;
}

.nfts,
.kycs {
    border: 2px solid #E5ECFF;
    padding: 50px;
    background-color: #fff;
}

.benefit {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
    padding-bottom: 80px;
}


.benefit-img {
    position: relative;
}

.benefit-img::before {
    position: absolute;
    content: "";
    background-image: url(../image/what.svg);
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
}

.ssi-wrapper {
    background-color: #F8F3E9;
    padding: 80px 0;
}

.ssi {
    text-align: center;
}


.combine {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 80px;
    padding-bottom: 80px;
}

.combine-img {
    background-image: url(../image/nft-kyc.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}


.text-center h2{
    color: #000;
    font-weight: 300;
  }
  
  .faq {
    border-radius: 10px;
  }
  
  .faq-wrapper{
      padding-bottom: 100px;
    position: relative;
    z-index: 2;
  }
  .faq-section-wrapper{
    padding-bottom: 90px;
  }
   .faq-wrapper {
    padding-top: 26px;
    }
    .center {
        padding-bottom: 25px;
    }
  
  .faq.expanded+.faq {
    border-top: 0
  }
  
  
  .faq-title {
    align-items: center;
    display: flex;
    justify-content: space-between
  }
  
  .faq-title h3 {
    font-weight: 400;
    line-height: 25px;
    pointer-events: none;
    margin-bottom: 0 !important;
    margin-top: 0;
    padding: 20px 0;
  }
  .faq-btn {
    align-items: center;
    border: 0;
    border-radius: 25px;
    display: flex;
    font-size: 100px;
    font-weight: 200;
    height: 49px;
    justify-content: center;
    width: 49px;
    position: relative;
  
  }
  
  .faq.collapsed .faq-btn::before {
    position: absolute;
    content: "";
    width: 3px;
    height: 22px;
    background-color: #3A6BEA;
    right: 9px;
    border-radius: 10px;
    transition: all 0.2 ease-in-out;
  }
  
  .faq-btn::after {
    position: absolute;
    content: "";
    width: 22px;
    height: 3px;
    background-color: #3A6BEA;
    right: 0px;
    border-radius: 10px;
  }
  
  .faq.expanded .faq-btn::before {
    transform: rotate(90deg);
    transition: all 0.2s ease;
  }
  
  .faq.collapsed .faq-btn::before {
    transform: rotate(0);
  }
  
  
  .faq-btn:hover,.faq-title:hover {
    cursor: pointer
  }
  
  .faq {
    transition: background-color .2s linear,font-weight .2s linear,border-radius .2s linear;
    position: relative;
    padding-bottom: 0;
    padding-left: 40px;
    padding-right: 40px;
  }
  
  .faq::after {
    position: absolute;
    content: "";
    width: 98%;
    height: 1px;
    background-color: #D1E8FF;
    bottom: 0;
    left: 1%;
  }
  
  .faq.expanded::after {
    content: none;
  }
  
  .faq-background-outer{
    border: 1px solid #3B495F;
    position: absolute;
    border-radius: 40px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  .faq-content{
    position: relative;
    z-index: 2;
  }
  
  .faq .faq-body {
    color: #3B495F;
    font-size: 17px;
    line-height: 24px;
    padding-bottom: 44px;
    padding-top: 0px;
    width: 95%;
  }
  .faq.collapsed .faq-body {
    display: none
  }
  .faq-body a{
    font-weight: bold;
    color: #000;
    text-decoration: underline;
  }
  
  .faq-body p {
    font-weight: 500;
  }
  
  .faq-body p b {
    font-weight: 700;
  }
  
  .faq.collapsed {
      padding: 20px 40px;
  }
  
  .faq.expanded {
      padding-top: 20px;
      background-color: #fff;
      margin-top: -1px;
      border: 1px solid #3A6BEA;
      border-radius: 2px;
  }
  
  .faq-btn span {
    pointer-events: none
  }
  
  .faq.collapsed .faq-btn span.plus,.faq.expanded .faq-btn span.minus {
    display: block
  }
  
  .faq.collapsed .faq-btn span.minus,.faq.expanded .faq-btn span.plus {
    display: none
  }
  .faq.expanded .faq-title h3 {
    font-weight: 800;
  }
  .text-center {
    text-align: center;
    color: #000;
  }
  .text-center p{
    padding-bottom: 50px;
    color: #000;
  }
  .faq-title h3{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #2C395A;
  }
  
  .faq-body p{
    color: #000;
    text-align: left;
    font-family: 'Lato';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 26px;
color: #2C395A;
  } 
  
  .faq-wrapper h2,
  .center {
        text-align: center;
  }
  
  .faq-bg {
    background: var(--main-bg-color);
  }
  
  .faq-wrapper {
    padding: 100px 0 80px;
  }

  .our-team-wrapper {
      display: grid;
      grid-template-columns: 55% 1fr;
  }

  .our-team-content {
      background-color: #E5ECFF;
      padding: 60px;
  }

  .contact-us-content {
      background-color: #F8F3E9;
      padding: 60px;
  }

  .contact-cta {
      background-color: #EB5D60;
      color: #fff;
      padding: 26px 80px;
      font-weight: 800;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 45px;
    display: inline-block;
    position: relative;
  }
  footer .logo-wrapper {
      padding: 12px 0;
      margin-top: 20px;
  }

  footer .logo-wrapper a,
  .copyright {
      font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #777C87;
  }

  footer .container {
      display: none;
  }

  @media only screen and (max-width: 992px) {
    .logo-wrapper ul {
        gap: 39px;
    }

    h1 {
        margin-left: -30px;
    }

    .site-header-img::before {
        top: calc(50% - 175px);
    }

    .site-header .container::before {
        height: 1115px;
    }

    .nfts,
    .kycs {
        padding: 33px;
    }

    .combine-img {
        background-position: top;
    }

    .contact-cta {
        padding: 26px 18px;
    }

  }

@media only screen and (max-width: 768px) {
    .container {
        padding: 0 20px;
    }

    .site-header .container::before {
        left: -20%;
    }

    h1 {
        font-size: 30px;
        line-height: 37px;
        margin-left: 0;
        position: relative;
        z-index: 4;
    }

    h2 {
        font-size: 26px;
        line-height: 34px;
    }

    .logo-wrapper ul {
        display: none;
    }

    .site-header-inner {
        grid-template-columns: 1fr;
        padding-top: 0;
    }

    .site-header-img::before {
        content: none;
    }

    .mobile-hero-img {
        width: 100%;
        height: 220px;
        position: relative;
    }

    .mobile-hero-img::before {
        position: absolute;
        content: "";
        background-image: url(../image/kyc-hero.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        top: -50px;
        width: 470px;
        height: 235px;
        left: -33px;
    }

    .what-are {
        padding: 35px 0;
    }

    .what-are-wrapper {
        grid-template-columns: 1fr;
        margin-top: 0;
    }

    .combine {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .mobile-combine-img {
        width: 100%;
        height: 250px;
        background-image: url(../image/nft-kyc.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin-bottom: 20px;
    }

    .benefit {
        grid-template-columns: 1fr;
        gap: 0;
        padding-bottom: 50px;
    }

    .mobile-benefit-img {
        width: 100%;
        height: 250px;
        background-image: url(../image/what.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin-bottom: 20px;
    }

    .ssi-wrapper,
    .faq-wrapper {
        padding: 44px 0;
    }

    .faq {
        padding-left: 20px;
        padding-right: 20px;
    }

    .faq.collapsed {
        padding: 20px;
    }

    .our-team-wrapper {
        grid-template-columns: 1fr;
        text-align: center;
    }

    .our-team-content,
    .contact-us-content {
        padding: 33px 0;
        position: relative;
    }

    .our-team-content::before {
        width: 200%;
        height: 100%;
        position: absolute;
        content: "";
        background-color: #E5ECFF;
        z-index: -2;
        top: 0;
        left: -50%;
    }

    .contact-us-content::before {
        width: 200%;
        height: 100%;
        position: absolute;
        content: "";
        background-color: #F8F3E9;
        z-index: -2;
        top: 0;
        left: -50%;
    }

    .our-team-content footer {
        display: none
    }

    footer .container {
        display: block;
    }

    footer .logo-wrapper {
        justify-content: center;
    }

    footer .copyright {
        display: flex;
        justify-content: center;
        padding-bottom: 20px;
    }

}
